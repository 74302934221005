import React from "react";
import { Link } from "react-router-dom";
import "../CSS/Footer.css";
import Logo from "../Img/logo.png";
import { IoCallOutline } from "react-icons/io5";
import { IoMdTime } from "react-icons/io";
import { FiMail } from "react-icons/fi";

const Footer = () => {
  return (
    <>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-cta pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
                  <IoCallOutline />
                  <div className="cta-text">
                    <h4>Call us</h4>
                    <a href="tel:+91 8013161010" target="_blank">
                      <span>+91 8013161010</span>
                    </a> <span>/</span>
                    <a href="tel:+91 9738161010" target="_blank">
                      <span>+91 9738161010 </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                <IoMdTime />
                  <div className="cta-text">
                    <h4>Timing</h4>
                    <a href="tel:+91 992326688" target="_blank">
                      <span>Every Day : 07:00 AM - 08:00 PM</span>
                    </a> <br/>
                    {/* <a href="tel:+91 7387938678" target="_blank">
                      <span>Tue - Sun : 09:00 AM - 07:30 PM</span>
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                <FiMail />  
                  <div className="cta-text">
                    <h4>Mail us</h4>
                    <a href="mailto:ravajipatilresort@gmai.com" target="_blank">
                      <span>ravajipatilresort@gmail.com</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link href="/">
                      <img
                        src={Logo}
                        className="img-fluid"
                        alt="ravji patil resort logo"
                      />
                    </Link>
                  </div>
                  <div className="footer-text">
                    <p>
                    Discover a haven of luxury and tranquility at Ravaji Patil Resort in Pune, where every moment is a symphony of comfort amidst nature's embrace.
                    </p>
                  </div>
                  
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Useful Links</h3>
                  </div>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/About">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link to="/Services">
                        Services
                      </Link>
                    </li>
                    <li>
                      <Link to="/Gallerys">
                      Gallery
                      </Link>
                    </li>
                    <li>
                      <Link to="/Contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Address</h3>
                  </div>
                  <div className="footer-text mb-25">
                    <p>
                    Avalon Building, Shop No-10, Anand Nagar,  Suncity Road, Sinhgad Road, Near Police Station, Pune, Maharashtra
                    </p>
                  </div>
                  <div className="footer-social-icon">
                    <span>Follow us</span>
                    <a href="https://www.facebook.com/ravajipatilresortpanshet">
                      <i className="fa fa-facebook facebook-bg" />
                    </a>
                    <a href="https://www.instagram.com/ravaji_patil_resort/">
                      <i className="fa fa-instagram google-bg" />
                    </a>
                    <a href="https://wa.me/+919738161010">
                      <i class="fa fa-whatsapp watsapp-bg" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                <div className="copyright-text">
                  <p>
                    Copyright © 2023, All Right Reserved{" "}
                    <a href="https://skdm.in">
                    Ravaji Patil Resort
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 text-center text-right">
              <div className="copyright-text">
                  <p>
                    Develope By : 
                    <a href="https://skdm.in">
                       Shree Krishna Digital Marketing
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
