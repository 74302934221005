import React, { useState } from "react";
import "../CSS/Contact.css";
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal";
import { IoCallOutline } from "react-icons/io5";
import { IoMdTime } from "react-icons/io";
import { GrLocation } from "react-icons/gr";
import { FiMail } from "react-icons/fi";
import Footer from "../Components/Footer";
import $ from "jquery";
import { MetaTags } from "react-meta-tags";

const Contact = () => {
  const [errors, setErrors] = useState({
    custName: "",
    custEmail: "",
    custContact: "",
    custMessage: "",
  });

  const handleValidation = () => {
    let isValid = true;
    const newErrors = {
      custName: "",
      custEmail: "",
      custContact: "",
      custMessage: "",
    };
    if (!custName.trim()) {
      isValid = false;
      newErrors.custName = "Name is required";
    }

    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    if (!custEmail.match(emailRegex)) {
      isValid = false;
      newErrors.custEmail = "Email is not valid";
    }

    if (!custContact.trim()) {
      isValid = false;
      newErrors.custContact = "Phone is required";
    } else if (!/^\d{10}$/.test(custContact)) {
      isValid = false;
      newErrors.custContact = "Phone must have 10 digits";
    }
    if (!custMessage.trim()) {
      isValid = false;
      newErrors.custMessage = "Write a Message";
    }

    setErrors(newErrors);
    return isValid;
  };
  // ============ Mail function ==========
  const [custName, setCustName] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custMessage, setCustMessage] = useState("");
  // ============== Mail Print ======
  const ServForm = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      var body =
      '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#d08b4d">Ravaji Patil Resort</h2><p>Hello Ravaji Patil Resort,</p><p>Thank you for your interest in our services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left;color:#d08b4d">' + custName + '</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left;color:#d08b4d">' + custEmail + '</td></tr><tr><td style="text-align:left"><strong>Phone:</strong></td><td style="text-align:left;color:#d08b4d">' + custContact + '</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left;color:#d08b4d">' + custMessage + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at<span style="text-align:left;color:#d08b4d;padding-left:5px">Ravaji Patil Resort</span>.</p></div></body></html>';
      $.post(
        "https://skdm.in/server/v1/send_lead_mail.php",
        {
          toEmail: "ravajipatilresort@gmail.com",
          fromEmail: "skdmlead@gmail.com",
          bccMail: "skdmlead@gmail.com",
          mailSubject: "New Customer Lead",
          mailBody: body,
          accountName: "ravaji patil resort",
          accountLeadSource: "https://ravajipatilresort.com",
          accountLeadName: custName,
          // accountLeadEmail: custEmail,
        },
        function (dataa, status) {
          console.log("data :" + dataa);
          console.log("name:" + custName);
          console.log("name:" + custEmail);
        }
      );

      alert("Your Form has Submitted Our team will contact with You  soon.");
      e.preventDefault();
      return false;
    }
  };
  return (
    <>
          {/* ========= Seo start========== */}
          <MetaTags>
        <title>Resort In Pune With Private Pool | Ravaji Patil Resort</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Resort In Pune With Private Pool | Ravaji Patil Resort" />
        <meta property="og:description" content="" />
        <meta name="keywords" content="resort in pune with private pool" />
        <link rel="canonical" href="https://ravajipatilresort.com" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ========== Contact Banner ======== */}
      <div className="Contact-banner">
        <div className="ContactBanner-Txt">
          <h1 className="d-none">resort in pune with private pool</h1>
          <Zoom>
            <h2>Contact</h2>
          </Zoom>
          <p>
          For inquiries or reservations at the ultimate family-friendly retreat, contact Ravaji Patil Resort in Pune
          </p>
        </div>
      </div>

      {/* =========== Contact Section ========== */}
      <section className="Contact-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 contact-column">
            <Slide left>
              <div className="left">
                <div className="left-Txt">
                  <span className="left-title">Stay With Us</span>
                  <h2>Contact Detail</h2>
                </div>
                <div className="contact-form">
                  <div className="row mt-3">
                    <div className="col-2">
                      <div className="contact-icon">
                      <GrLocation />
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="contact-desc">
                        <h4>Address</h4>
                        <p>
                          <a href="https://maps.app.goo.gl/boGkFetQ3Qptg759A">
                          Avalon Building, Shop No-10, Anand Nagar,  Suncity Road, Sinhgad Road, Near Police Station, Pune, Maharashtra
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-2">
                      <div className="contact-icon">
                        <IoCallOutline/>
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="contact-desc">
                        <h4>Contact</h4>
                        <p>
                          <a href="tel:+91-8013161010">+91-8013161010</a>
                          <br/>
                          <a href="tel:+919738161010 ">+91-9738161010 </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-2">
                      <div className="contact-icon">
                      <FiMail />
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="contact-desc">
                        <h4>Mail</h4>
                        <p>
                          <a href="mailto:ravajipatilresort@gmai.com">
                          ravajipatilresort@gmai.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-2">
                      <div className="contact-icon">
                      <IoMdTime />
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="contact-desc">
                        <h4>Timing</h4>
                        <p>
                        Every Day : 07:00 AM - 08:00 PM
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </Slide>
            </div>
            <div className="col-lg-6 contact-content">
              <div className="right">
                <div className="right-Txt">
                  <h2>Enquiry Now</h2>
                </div>
                <div className="contact-form">
                <div className="row">
                  <div class="col-sm-6 mb-2">
                    <div class="form-group">
                      <label for="name">Your Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={custName}
                        onChange={(e) => setCustName(e.target.value)}
                      />
                      {errors.custName && (
                        <span className="error-text">{errors.custName}</span>
                      )}
                    </div>
                  </div>
                  <div class="col-sm-6 mb-2">
                    <div class="form-group">
                      <label for="name">Your Email</label>
                      <input
                        class="form-control"
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={custEmail}
                        onChange={(e) => setCustEmail(e.target.value)}
                      />
                      {errors.custEmail && (
                        <span className="error-text">{errors.custEmail}</span>
                      )}
                    </div>
                  </div>
                  <div class="col-12 mb-2">
                    <div class="form-group">
                      <label for="name">Phone</label>
                      <input
                        className="form-control"
                        type="number"
                        name="contact"
                        placeholder="Contact No"
                        value={custContact}
                        onChange={(e) => setCustContact(e.target.value)}
                      />
                      {errors.custContact && (
                        <span className="error-text">{errors.custContact}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-12 mb-2">
                    <div class="form-group">
                      <label for="name">Message</label>
                      <textarea
                        class="form-control"
                        name="message"
                        placeholder="Message"
                        id="custMessage1"
                        value={custMessage}
                        rows={6}
                        onChange={(e) => setCustMessage(e.target.value)}
                      ></textarea>
                      {errors.custMessage && (
                        <span className="error-text">{errors.custMessage}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-12 mb-2">
                    <div class="form-btn">
                      <button className="standard-btn" onClick={ServForm}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========= Contact Map =========== */}
      <div className="contact-map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15148.56546677274!2d73.51613!3d18.340834!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc29d9b15d78d53%3A0x3edc3f86aed1fdd9!2sRavaji%20Patil%20Resorts!5e0!3m2!1sen!2sus!4v1701332708525!5m2!1sen!2sus" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
