import React from 'react';
import "../CSS/About.css";
import AboutImg from "../Img/logo.png";
import { Link } from 'react-router-dom';
import Footer from '../Components/Footer';
import { Zoom } from 'react-reveal';
import { Slide } from 'react-reveal';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
// ======= Testimonal Img =====
import Client1 from "../Img/testimonial/t1.jpg";
import Client2 from "../Img/testimonial/t2.jpg";
import { IoIosStar } from "react-icons/io";
import { IoIosStarHalf } from "react-icons/io";
import { MetaTags } from 'react-meta-tags';

const About = () => {
  return (
    <>
          {/* ========= Seo start========== */}
          <MetaTags>
        <title>Resort Near Pune | Ravaji Patil Resort</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Resort Near   Pune | Ravaji Patil Resort" />
        <meta property="og:description" content="" />
        <meta name="keywords" content="resort near pune" />
        <link rel="canonical" href="https://ravajipatilresort.com" />
      </MetaTags>
      {/* ======== Seo end ======= */}
    {/* ========== About Banner ======== */}
    <div className="About-banner">
     <div className="AboutBanner-Txt">
       <h1 className="d-none">resort near pune</h1>
       <Zoom>
         <h2>About</h2>
       </Zoom>
       <p>
       Dive into the story of luxury, tranquility, and impeccable hospitality at Ravaji Patil Resort
       </p>
     </div>
   </div>
      {/* ====== About Section Page ====== */}
      <section className="about-section">
        <div className="container">
          <div className="row">
            <div className="image-column col-lg-6 col-md-12 col-sm-12">
         <Slide left>
        <div className="inner-column">
          <div className="author-desc">
            <h5>Ravaji Patil Resort</h5>
          </div>
          <Slide top>
          <div className="image-1">
            <Link to="/About" className="lightbox-image">
              <img
                src={AboutImg}
                alt="about ravaji patil resort"
              />
            </Link>
          </div>
          </Slide>
        </div>
        </Slide>
      </div>
      <div className="content-column col-lg-6 col-md-12 col-sm-12">
      <Zoom>
        <div className="inner-column">
          <div className="sec-title">
            <span>About Ravaji Patil</span>
            <h2>Ravaji Patil Resort</h2>
          </div>
          <div className="text">
            <p className='mb-5'>Our resort offers an escape into tranquility, with luxurious amenities and personalized service, making it the perfect destination for a serene retreat in Pune. 
            Immerse yourself in the charm of Pune at Ravaji Patil Resort, where the allure of nature meets refined elegance. From well-appointed accommodations to picturesque landscapes, our resort is designed to provide an enchanting experience, ensuring every guest discovers relaxation and luxury in the heart of Pune</p>
            
          </div>
        </div>
        </Zoom>
      </div>
    </div>
  </div>
      </section>
      {/* ========== Why Choose Us ======== */}
      {/* <section className="choose-us">
        <div className="container">
        <div className="custom-heading">
            <h2>
              <span>Why</span> Choose Us  
            </h2>
          </div>
          <div className="row">
            <div className="col-md-4">
            <div className="choose-item">
                  <div className="choose-img">
                    <img src={AboutImg} alt="ravaji patil"/>
                  </div>
                  <div className="choose-icon">
                    <i>
                      <img src={AboutImg} alt="ravaji patil" />
                    </i>
                  </div>
                  <div className="choose-content">
                    <h4>
                      Our Mission
                    </h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos assumenda nobis eum voluptatem inventore aut maiores accusantium voluptas expedita. Id illo quisquam cumque in, quasi dolores nam placeat nisi quod.
                    </p>
                  </div>
                </div>
            </div>
            <div className="col-md-4">
            <div className="choose-item">
                  <div className="choose-img">
                    <img src={AboutImg} alt="ravaji patil"/>
                  </div>
                  <div className="choose-icon">
                    <i>
                      <img src={AboutImg} alt="ravaji patil" />
                    </i>
                  </div>
                  <div className="choose-content">
                    <h4>
                      Our Mission
                    </h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos assumenda nobis eum voluptatem inventore aut maiores accusantium voluptas expedita. Id illo quisquam cumque in, quasi dolores nam placeat nisi quod.
                    </p>
                  </div>
                </div>
            </div>
            <div className="col-md-4">
            <div className="choose-item">
                  <div className="choose-img">
                    <img src={AboutImg} alt="ravaji patil"/>
                  </div>
                  <div className="choose-icon">
                    <i>
                      <img src={AboutImg} alt="ravaji patil" />
                    </i>
                  </div>
                  <div className="choose-content">
                    <h4>
                      Our Mission
                    </h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos assumenda nobis eum voluptatem inventore aut maiores accusantium voluptas expedita. Id illo quisquam cumque in, quasi dolores nam placeat nisi quod.
                    </p>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section> */}
            {/* ========= AminitieSection ============  */}
            <section className="aminitiesSection">
        <div className="container">
        <div className="custom-heading">
            <h2>
              <span>Our</span> Aminities 
            </h2>
          </div>
          <div className="row row-cols-lg-6 row-cols-md-4 row-cols-sm-3 row-cols-3 d-flex justify-content-center align-items-center">
            <div className="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div className="aminitiCard">
                <span className="material-icons-outlined">wifi</span>
                <h3>Complimentary Wi-Fi</h3>
              </div>
            </div>
            <div className="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div className="aminitiCard">
                <span className="material-icons-outlined">beach_access</span>
                <h3>Private Beach</h3>
              </div>
            </div>
            <div className="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div className="aminitiCard">
                <span className="material-icons-outlined">bedroom_parent</span>
                <h3>Air Conditioner Room</h3>
              </div>
            </div>
            {/* <div className="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div className="aminitiCard">
                <span className="material-icons-outlined">ondemand_video</span>
                <h3>Television</h3>
              </div>
            </div> */}
            <div className="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div className="aminitiCard">
                <span className="material-icons-outlined">drive_eta</span>
                <h3>Pick Up & Drop</h3>
              </div>
            </div>
            <div className="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div className="aminitiCard">
                <span className="material-icons-outlined">restaurant</span>
                <h3>Restaurant</h3>
              </div>
            </div>
            <div className="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div className="aminitiCard">
                <span className="material-icons-outlined">solar_power</span>
                <h3>Power Backup</h3>
              </div>
            </div>
            <div className="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div className="aminitiCard">
                <span className="material-icons-outlined">
                  table_restaurant
                </span>
                <h3>Open Sky Dining</h3>
              </div>
            </div>
            {/* <div className="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div className="aminitiCard">
                <span className="material-icons-outlined">
                  cleaning_services
                </span>
                <h3>Daily Housekeeping</h3>
              </div>
            </div> */}
            <div className="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div className="aminitiCard">
                <span className="material-icons-outlined">
                  local_fire_department
                </span>
                <h3>Bonfire</h3>
              </div>
            </div>
            <div class="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div class="aminitiCard">
                <span class="material-icons-outlined">water_drop</span>
                <h3>Water Sports</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =========  Testimonal Section ============  */}
      <section className="testimonalSection ">
        <div className="container">
        <div className="custom-heading">
            <h2>
              <span>What Our</span> Guest's Say
            </h2>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay, Navigation]}
            className="testimonalSwiper"
          >
            <SwiperSlide>
            <Slide left>
              <div className="item">
                <div className="test-cont">
                  <p>
                    "We spend wonderful 2night (24/25jan) at this calm, hill top resort. The view from balcony of rooms was really wonderful. Those who love the calm nature this is the perfect place and best lake view. The artistic interior u feel everywhere"
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={Client1} alt="ravaji patil testimmonal" />
                  </div>
                  <div className="client_name">
                    <h5>Rajesh Nivangune , Pune </h5>
                    <ul>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i><IoIosStarHalf /></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              </Slide>
            </SwiperSlide>
            <SwiperSlide>
            <Slide left>
              <div className="item">
                <div className="test-cont">
                  <p>
                    "Best place for the weekend Nice food Good service Great location."Very scenic location...with yummy food n super friendly n co-operative staff !!  "
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={Client2} alt="testimonal" />
                  </div>
                  <div className="client_name">
                    <h5>Sachin Vinchurkar , Pune</h5>
                    <ul>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i><IoIosStarHalf /></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              </Slide>
            </SwiperSlide>
            <SwiperSlide>
            <Slide left>
              <div className="item">
                <div className="test-cont">
                  <p>
                    "The resort was very nice and beautiful. The rooms are clean
                    and tidy, very near to beach. As we open the windows we see
                    a beautiful beach without any crowd. Very calm and quit
                    place to stay.The food was very tasty and beautifully
                    served. The owner and staff were very polite and friendly."
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={Client1} alt="testimonal" />
                  </div>
                  <div className="client_name">
                    <h5>Shradha Gajbhiye </h5>
                    <ul>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i><IoIosStarHalf /></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              </Slide>
            </SwiperSlide>
            <SwiperSlide>
            <Slide left>
              <div className="item">
                <div className="test-cont">
                  <p>
                    "The resort was very nice and beautiful. The rooms are clean
                    and tidy, very near to beach. As we open the windows we see
                    a beautiful beach without any crowd. Very calm and quit
                    place to stay.The food was very tasty and beautifully
                    served. The owner and staff were very polite and friendly."
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={Client2} alt="testimonal" />
                  </div>
                  <div className="client_name">
                    <h5>Shradha Gajbhiye </h5>
                    <ul>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i><IoIosStarHalf /></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              </Slide>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    <Footer/>
    </>
  )
}

export default About;
