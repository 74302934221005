// =========== Insta Img ======
import Gallery1 from "../Img/gallery/gallery1.jpg";
import Gallery2 from "../Img/gallery/gallery2.jpg";
import Gallery3 from "../Img/gallery/gallery3.jpg";
import Gallery4 from "../Img/gallery/gallery4.jpg";
import Gallery5 from "../Img/gallery/gallery7.jpg";
import Gallery6 from "../Img/gallery/gallery9.jpg";
import Gallery7 from "../Img/gallery/room1.jpg";
import Gallery8 from "../Img/gallery/room2.jpg";
import Gallery9 from "../Img/gallery/banner1.jpg";
import Gallery10 from "../Img/gallery/banner2.jpg";
import Gallery11 from "../Img/gallery/gallery14.jpg";
import Gallery12 from "../Img/gallery/banner5.jpg";

const photos = [
    {
        src:Gallery1,
        width:3,
        height:3,
      },
      {
        src:Gallery2,
        width:3,
        height:2,
      },
      {
        src:Gallery3,
        width:3,
        height:3,
      },
      {
        src:Gallery4,
        width: 3,
        height: 2,
      },
      {
        src:Gallery5,
        width:3,
        height:2,
      },
      {
        src:Gallery6,
        width:3,
        height:2,
      },
      {
        src:Gallery7,
        width:3,
        height:3,
      },
      {
        src:Gallery8,
        width:3,
        height:2,
      },
      {
        src:Gallery9,
        width:3,
        height:3,
      },
      {
        src:Gallery10,
        width:3,
        height:3,
      },
      {
        src:Gallery11,
        width:3,
        height:2,
      },
      {
        src:Gallery12,
        width:3,
        height:3,
      },
      
];

export default photos;