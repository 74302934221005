import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { TiEyeOutline } from "react-icons/ti";
import "../CSS/Home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";
import { Bounce, Flip } from "react-reveal";
import { Link } from "react-router-dom";
import AboutImg from "../Img/logo.png";
// ======== Services Img ========
import TentStay from "../Img/service/tent-stay.webp";
import Food from "../Img/service/food.webp";
import ExcellentRoom from "../Img/activities/room.jpg";
import Activities from "../Img/service/fun-activities.jpeg";
import Massage from "../Img/service/massage.webp";
import Wedding from "../Img/service/pre-wedding.jpeg";
import Rain from "../Img/activities/rain-dance.jpg";
import Tarafa from "../Img/activities/Tarafa.jpg";
// ========= Activities Img ========
import Atvbike from "../Img/activities/atv-bike.webp";
import Boating from "../Img/activities/boating.webp";
import Bulakcar from "../Img/activities/bulak-car.webp";
import HorseRide from "../Img/activities/horse-ride.webp";
import Pool from "../Img/activities/pool.webp";
//============== Smile Img ========
import smile1 from "../Img/smile/s1.webp";
import smile2 from "../Img/smile/s2.webp";
import smile3 from "../Img/smile/s3.webp";
import smile4 from "../Img/smile/s4.webp";
import smile5 from "../Img/smile/s5.webp";
import smile6 from "../Img/smile/s6.webp";
import smile7 from "../Img/smile/s7.webp";
import smile8 from "../Img/smile/s8.webp";
// ======= Testimonal Img =====
import Client1 from "../Img/testimonial/t1.jpg";
import Client2 from "../Img/testimonial/t2.jpg";
import { IoIosStar } from "react-icons/io";
import { IoIosStarHalf } from "react-icons/io";
import Footer from "../Components/Footer";
import EnquiryModal from '../Components/EnquiryModal';
import { MetaTags } from "react-meta-tags";

const Home = () => {
  // ========== Smile Gallery ========
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const images = [smile1, smile2, smile3, smile4, smile5, smile6, smile7, smile8];

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  // popup Modal
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      {/* ========= Seo start========== */}
      <MetaTags>
        <title>Resort In Pune | Ravaji Patil Resort</title>
        <meta name="description" content="" />
        <meta property="og:title" content="resort in pune | Ravaji Patil Resort" />
        <meta property="og:description" content="" />
        <meta name="keywords" content="resort in pune" />
        <link rel="canonical" href="https://ravajipatilresort.com" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ============ Banner Section =========== */}
      <div className="bannerSection">
        <Swiper
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="Home_Banner"
        >
          <SwiperSlide>
            <div className="home_Banner1">
              <div className="homeBanner_Txt">
                <h1 className="d-none">resort in pune</h1>
                <Zoom>
                  <h2>Escape to Paradise Your Ultimate Resort Retreat</h2>
                </Zoom>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="home_Banner2">
              <div className="homeBanner_Txt">
              <Zoom>
                  <h2>Unwind in Luxury Where Every Moment Feels Like a Vacation</h2>
                </Zoom>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="home_Banner3">
              <div className="homeBanner_Txt">
              <Zoom>
                  <h2>Adventure Fun Activities, Thrills Unleashed</h2>
                </Zoom> 
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      {/* ====== About Section ====== */}
      <section className="about-section">
        <div className="container">
          <div className="row">
            <div className="image-column col-lg-6 col-md-12 col-sm-12">
         <Slide left>
        <div className="inner-column">
          <div className="author-desc">
            <h5>Ravaji Patil Resort</h5>
          </div>
          <Slide top>
          <div className="image-1">
            <Link to="/About" className="lightbox-image">
              <img
                src={AboutImg}
                alt="about ravaji patil"
              />
            </Link>
          </div>
          </Slide>
        </div>
        </Slide>
      </div>
      <div className="content-column col-lg-6 col-md-12 col-sm-12">
      <Zoom>
        <div className="inner-column">
          <div className="sec-title">
            <span>About Ravaji Patil</span>
            <h2>Ravaji Patil Resort</h2>
          </div>
          <div className="text">
            <p className='mb-5'>Our resort offers an escape into tranquility, with luxurious amenities and personalized service, making it the perfect destination for a serene retreat in Pune. 
            Immerse yourself in the charm of Pune at Ravaji Patil Resort, where the allure of nature meets refined elegance. From well-appointed accommodations to picturesque landscapes, our resort is designed to provide an enchanting experience, ensuring every guest discovers relaxation and luxury in the heart of Pune</p>
            <Link to="/" class="standard-btn">Read More</Link>
          </div>
        </div>
        </Zoom>
      </div>
    </div>
  </div>
      </section>
      {/* ======== Service Section ============ */}
      <section className="home-service">
        <div className="container">
          <div className="custom-heading">
            <h2>
              <span>Our </span> Services
            </h2>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6 col-12 mb-md-5 mb-3">
            <Zoom>
              <div className="service-card">
                <div className="service-img">
                  <img src={Food} alt="resort food" />
                </div>
                <div className="service-desc">
                  <h4>Delicious Food</h4>
                  <p>Indulge in a gourmet retreat at Ravaji Patil Resort in Pune, where every dish is a symphony of flavors, set amidst serene surroundings for an unforgettable dining experience</p>
                </div>
              </div>
              </Zoom>
            </div>
            <div className="col-md-4 col-sm-6 col-12 mb-md-5 mb-3">
            <Zoom>
              <div className="service-card">
                <div className="service-img">
                  <img src={Massage} alt="massage in resort" />
                </div>
                <div className="service-desc">
                  <h4>Foot Massager</h4>
                  <p>Relax at Ravaji Patil Resort in Pune, where our deluxe foot massagers add an extra touch of comfort to your tranquil retreat. Pamper yourself in the lap of luxury, ensuring a truly soothing experience during your stay.</p>
                </div>
              </div>
              </Zoom>
            </div>
            <div className="col-md-4 col-sm-6 col-12 mb-md-5 mb-3">
            <Zoom>
              <div className="service-card">
                <div className="service-img">
                  <img src={Tarafa} alt="resort tarafa" />
                </div>
                <div className="service-desc">
                  <h4>Floating Tarafa</h4>
                  <p>Experience serenity at Ravaji Patil Resort in Pune with our Floating Tarafa, creating a unique blend of relaxation and nature amidst the resort's picturesque surroundings</p>
                </div>
              </div>
              </Zoom>
            </div>
            <div className="col-md-4 col-sm-6 col-12 mb-md-5 mb-3">
            <Zoom>
              <div className="service-card">
                <div className="service-img">
                  <img src={Wedding} alt="pre wedding shoot" />
                </div>
                <div className="service-desc">
                  <h4>Pre Wedding Shoot</h4>
                  <p>Capture timeless moments amid the enchanting backdrop of Ravaji Patil Resort in Pune, offering a dreamy setting for your pre-wedding shoot. Creating memories to cherish in this luxurious Pune resort</p>
                </div>
              </div>
              </Zoom>
            </div>
            <div className="col-md-4 col-sm-6 col-12 mb-md-5 mb-3">
            <Zoom>
              <div className="service-card">
                <div className="service-img">
                  <img src={TentStay} alt="tent stay in resort" />
                </div>
                <div className="service-desc">
                  <h4>Tent Stay</h4>
                  <p>Discover the essence of tranquility with a tent stay at Ravaji Patil Resort in Pune, where nature embraces luxury for an unforgettable resort retreat. Experience the rustic charm and comfort in the heart of Pune's scenic beauty.</p>
                </div>
              </div>
              </Zoom>
            </div>
            <div className="col-md-4 col-sm-6 col-12 mb-md-5 mb-3">
            <Zoom>
              <div className="service-card">
                <div className="service-img">
                  <img src={ExcellentRoom} alt="resort room" />
                </div>
                <div className="service-desc">
                  <h4>Guest Rooms</h4>
                  <p>Ravaji Patil Resort in Pune, where our guest rooms combine elegance with comfort, creating the perfect retreat in the heart of Pune's beauty. Experience luxury and tranquility seamlessly intertwined for an unforgettable stay at our resort.</p>
                </div>
              </div>
              </Zoom>
            </div>
            <div className="col-md-4 col-sm-6 col-12 mb-md-5 mb-3">
            <Zoom>
              <div className="service-card">
                <div className="service-img">
                  <img src={Rain} alt="rain dance in resort" />
                </div>
                <div className="service-desc">
                  <h4>Rain Dance</h4>
                  <p>Dance under the monsoon magic at Ravaji Patil Resort in Pune, where our exhilarating rain dance adds a splash of joy to your resort experience. Enjoy the rhythm of nature and revel in the lively ambiance during your stay in Pune's ultimate retreat.</p>
                </div>
              </div>
              </Zoom>
            </div>
            <div className="col-md-4 col-sm-6 col-12 mb-md-5 mb-3">
            <Zoom>
              <div className="service-card">
                <div className="service-img">
                  <img src={Activities} alt="activities in resort" />
                </div>
                <div className="service-desc">
                  <h4>Fun Activities</h4>
                  <p>Dive into joy at Ravaji Patil Resort in Pune, where a spectrum of fun activities awaits, turning your stay into an unforgettable adventure in Pune's finest resort. Experience leisure and laughter in the heart of nature's embrace</p>
                </div>
              </div>
              </Zoom>
            </div>
          </div>
        </div>
      </section>
      {/* ====== Activitie Section ======= */}
      <section className="home-activitie">
        <div className="container">
          <div className="custom-heading">
            <h2>
              <span>Free</span> Activities
            </h2>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6 col-12 mb-md-5 mb-3">
              <Flip left>
                <div
                  className="activitieCard"
                >
                  <div className="activitieImg">
                    <img src={Boating} alt="boating in resort" />
                    <h4>Boating</h4>
                  </div>
                  <div className="activitieBody">
                    <h4>Boating</h4>
                   <p>Embark on a serene boating experience at Ravaji Patil Resort in Pune, where the tranquil waters complement the resort's natural beauty for a perfect retrea</p>
                    {/* <Link to="/" class="standard-btn">Read More</Link> */}
                  </div>
                </div>
              </Flip>
            </div>
            {/* <div className="col-md-4 col-sm-6 col-12 mb-md-5 mb-3">
              <Flip left>
                <div
                  className="activitieCard"
                >
                  <div className="activitieImg">
                    <img src={HorseRide} alt="horse riding" />
                    <h4>Horse Riding </h4>
                  </div>
                  <div className="activitieBody">
                    <h4>Horse Riding </h4>
                    <p>Gallop into adventure at Ravaji Patil Resort in Pune, where horse riding adds an exhilarating touch to your resort experience amidst picturesque surroundings</p>
                  </div>
                </div>
              </Flip>
            </div> */}
            {/* <div className="col-md-4 col-sm-6 col-12 mb-md-5 mb-3">
              <Flip left>
                <div
                  className="activitieCard"
                >
                  <div className="activitieImg">
                    <img src={Bulakcar} alt="bulak car" />
                    <h4>Bulak Car</h4>
                  </div>
                  <div className="activitieBody">
                    <h4>Bulak Car</h4>
                    <p>Explore the charm of Ravaji Patil Resort in Pune with our Bulak Car, adding a nostalgic and delightful touch to your resort escapade in Pune's serene ambiance</p>
                  </div>
                </div>
              </Flip>
            </div> */}
            <div className="col-md-4 col-sm-6 col-12 mb-md-5 mb-3">
              <Flip left>
                <div
                  className="activitieCard"
                >
                  <div className="activitieImg">
                    <img src={Atvbike} alt="atv bike" />
                    <h4>ATV Bike</h4>
                  </div>
                  <div className="activitieBody">
                    <h4>ATV Bike</h4>
                    <p>The excitement at Ravaji Patil Resort in Pune with ATV bike adventures, where thrilling rides complement the resort's picturesque setting for an unforgettable experience in Pune</p>
                    {/* <Link to="/" class="standard-btn">Read More</Link> */}
                  </div>
                </div>
              </Flip>
            </div>
            <div className="col-md-4 col-sm-6 col-12 mb-md-5 mb-3">
              <Flip left>
                <div
                  className="activitieCard"
                >
                  <div className="activitieImg">
                    <img src={Pool} alt="pool game" />
                    <h4>Pool</h4>
                  </div>
                  <div className="activitieBody">
                    <h4>Pool</h4>
                   <p>Make a splash of enjoyment at Ravaji Patil Resort in Pune with pool games, adding a refreshing dimension to your resort stay in Pune's tranquil ambiance</p>
                    {/* <Link to="/" class="standard-btn">Read More</Link> */}
                  </div>
                </div>
              </Flip>
            </div>
          </div>
        </div>
      </section>
      {/* ====== Smile Section ======= */}
      <section className="smile-section">
        <div className="container">
          <div className="custom-heading">
            <h2>
              <span>Stress</span> Free Smile
            </h2>
          </div>
           <div className="row row-cols-md-4 row-cols-sm-2 row-cols-1">
      {images.map((image, index) => (
        <div className="col mb-md-5 mb-3" key={index}>
           <Bounce>
          <div className="smile-img" onClick={() => openLightbox(index)}>
            <img src={image} alt="resort gallery" />
            <div className="smile-icon">
              <TiEyeOutline />
            </div>
          </div>
          </Bounce>
        </div>
      ))}
      {lightboxOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </div>
        </div>
      </section>
      {/* =========  Testimonal Section ============  */}
      <section className="testimonalSection">
        <div className="container">
        <div className="custom-heading">
            <h2>
              <span>What Our</span> Guest's Say
            </h2>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay, Navigation]}
            className="testimonalSwiper"
          >
            <SwiperSlide>
            <Slide left>
              <div className="item">
                <div className="test-cont">
                  <p>
                    "We spend wonderful 2night (24/25jan) at this calm, hill top resort. The view from balcony of rooms was really wonderful. Those who love the calm nature this is the perfect place and best lake view. The artistic interior u feel everywhere"
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={Client1} alt="review person" />
                  </div>
                  <div className="client_name">
                    <h5>Rajesh Nivangune , Pune </h5>
                    <ul>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i><IoIosStarHalf /></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              </Slide>
            </SwiperSlide>
            <SwiperSlide>
            <Slide left>
              <div className="item">
                <div className="test-cont">
                  <p>
                    "Best place for the weekend Nice food Good service Great location."Very scenic location...with yummy food n super friendly n co-operative staff !!  "
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={Client2} alt="client image" />
                  </div>
                  <div className="client_name">
                    <h5>Sachin Vinchurkar , Pune</h5>
                    <ul>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i><IoIosStarHalf /></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              </Slide>
            </SwiperSlide>
            <SwiperSlide>
            <Slide left>
              <div className="item">
                <div className="test-cont">
                  <p>
                    "The resort was very nice and beautiful. The rooms are clean
                    and tidy, very near to beach. As we open the windows we see
                    a beautiful beach without any crowd. Very calm and quit
                    place to stay.The food was very tasty and beautifully
                    served. The owner and staff were very polite and friendly."
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={Client1} alt="testimonal image" />
                  </div>
                  <div className="client_name">
                    <h5>Shradha Gajbhiye </h5>
                    <ul>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i><IoIosStarHalf /></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              </Slide>
            </SwiperSlide>
            <SwiperSlide>
            <Slide left>
              <div className="item">
                <div className="test-cont">
                  <p>
                    "The resort was very nice and beautiful. The rooms are clean
                    and tidy, very near to beach. As we open the windows we see
                    a beautiful beach without any crowd. Very calm and quit
                    place to stay.The food was very tasty and beautifully
                    served. The owner and staff were very polite and friendly."
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={Client2} alt="people review" />
                  </div>
                  <div className="client_name">
                    <h5>Shradha Gajbhiye </h5>
                    <ul>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i> <IoIosStar /></i>
                      </li>
                      <li>
                     <i><IoIosStarHalf /></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              </Slide>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      {/* =========  Enquiry Section ============  */}
      <section className="enquirySection">
        <div className="container">
        <div className="custom-heading">
            <h2>
              <span>Get</span> In Tuch
            </h2>
            <p>Seamless serenity awaits at Ravaji Patil Resort in Pune – your tranquil haven in the heart of nature. Connect with us to embark on a personalized escape, where the beauty of our resort seamlessly intertwines with Pune's enchanting landscapes. Contact our team, and let your journey to relaxation begin</p>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="enquiry-content">
              <button
                      type="submit"
                      class="standard-btn"
                      onClick={() => setModalShow(true)}
                    >
                      Get Quote
                    </button>
                    <EnquiryModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ====== footer Section ====== */}
      <Footer/>
    </>
  );
};

export default Home;
