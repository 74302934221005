import React from 'react';
import "../CSS/Services.css";
import { Zoom } from 'react-reveal';
import Slide from "react-reveal";
import TentStay from "../Img/service/tent-stay.webp";
import Food from "../Img/service/food.webp";
import ExcellentRoom from "../Img/service/excellent-room.webp";
import Activities from "../Img/service/fun-activities.jpeg";
import Massage from "../Img/service/massage.webp";
import Wedding from "../Img/service/pre-wedding.jpeg";
import Rain from "../Img/service/rain-dance.webp";
import Tarafa from "../Img/service/tarafa.webp";
import Footer from '../Components/Footer';
import { MetaTags } from 'react-meta-tags';

const Services = () => {
  return (
    <>
          {/* ========= Seo start========== */}
          <MetaTags>
        <title>Resort In Pune For Family | Ravaji Patil Resort</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Resort In Pune For Family | Ravaji Patil Resort" />
        <meta property="og:description" content="" />
        <meta name="keywords" content="resort in pune for family" />
        <link rel="canonical" href="https://ravajipatilresort.com" />
      </MetaTags>
      {/* ======== Seo end ======= */}
       {/* ========== Services Banner ======== */}
       <div className="Services-banner">
        <div className="ServicesBanner-Txt">
          <h1 className="d-none">resort in pune for family</h1>
          <Zoom>
            <h2>Services</h2>
          </Zoom>
          <p>
          Discover the exceptional offerings and tailored experiences that define luxury and comfort on Ravaji Patil Resort
          </p>
        </div>
      </div>
      {/* ============ Services Detail ====== */}
      <section className="Service_detail">
        <div className="container">
          <div className="custom-heading">
            <h2>
              <span>Services</span> Detail  
            </h2>
          </div>
          <div className="row mb-md-0 mb-5">
            <div className="col-md-6">
            <Zoom>
                <div className="servDetail-img">
                    <img src={Tarafa} alt="tarafa" />
                </div>
                </Zoom>
            </div>
            <div className="col-md-6">
            <Slide right>
                <div className="servDetail-txt">
                    <h4>Floating Tarafa</h4>
                    <p>Elevate your family retreat at Ravaji Patil Resort in Pune with the enchanting Floating Tarafa. This unique feature, set amidst the resort's picturesque surroundings, becomes the highlight of your stay, offering a perfect blend of relaxation and family-friendly activities.</p>
                    <p> Immerse yourself in the joy of shared moments as the Floating Tarafa becomes the focal point of your unforgettable family experience at Ravaji Patil Resort in Pune</p>
                </div>
                </Slide>
            </div>
          </div>
          <div className="row mb-md-0 mb-5">
            <div className="col-md-6 order-md-1 order-2">
              <Slide left>
                <div className="servDetail-txt">
                    <h4>Rain Dance</h4>
                    <p>Create lasting memories with your family at Ravaji Patil Resort in Pune, where the exhilarating Rain Dance experience takes center stage. This family-friendly resort is designed for ultimate enjoyment, offering a perfect blend of relaxation and entertainment.</p>
                    <p>Let the joy of dancing in the rain become the highlight of your family getaway at Ravaji Patil Resort, providing an unforgettable experience in the heart of Pune's natural beauty.</p>
                </div>
                </Slide>
            </div>
            <div className="col-md-6 order-md-2 order-1">
              <Zoom>
                <div className="servDetail-img">
                    <img src={Rain} alt="rain dance" />
                </div>
                </Zoom>
            </div>
          </div>
          <div className="row mb-md-0 mb-5">
            <div className="col-md-6">
            <Zoom>
                <div className="servDetail-img">
                    <img src={Wedding} alt="pre wedding shoot" />
                </div>
                </Zoom>
            </div>
            <div className="col-md-6">
            <Slide right>
                <div className="servDetail-txt">
                    <h4>Pre Wedding Shoot</h4>
                    <p>Elevate your pre-wedding shoot to a magical experience at Ravaji Patil Resort in Pune, where the stunning landscapes and luxurious amenities provide the perfect backdrop for capturing timeless moments. </p>
                    <p>This family-friendly resort offers a blend of sophistication and natural charm, ensuring that your pre-wedding moments are highlighted by the serenity and beauty of Pune's landscapes.</p>
                    <p>Ravaji Patil Resort stands as the ideal choice for families seeking not just a venue, but an enchanting setting for the celebration of love and togetherness in Pune.</p>
                </div>
                </Slide>
            </div>
          </div>
          {/* <div className="row mb-md-0 mb-5">
            <div className="col-md-6 order-md-1 order-2">
            <Slide right>
                <div className="servDetail-txt">
                    <h4>Foot Massager</h4>
                    <p>Indulge in ultimate relaxation at Ravaji Patil Resort in Pune, where luxury meets comfort, highlighted by our rejuvenating Foot Massager experience. Designed for families seeking a tranquil escape, our resort in Pune combines modern amenities with natural beauty. The Foot Massager adds a touch of pampering to your family retreat, ensuring that every member enjoys a blissful and unforgettable stay at Ravaji Patil Resort</p>
                </div>
                </Slide>
            </div>
            <div className="col-md-6 order-md-2 order-1">
            <Zoom>
                <div className="servDetail-img">
                    <img src={Massage} alt="massage" />
                </div>
                </Zoom>
            </div>
          </div> */}
          {/* <div className="row mb-md-0 mb-5">
            <div className="col-md-6">
            <Zoom>
                <div className="servDetail-img">
                    <img src={Activities} alt="fun activities" />
                </div>
                </Zoom>
            </div>
            <div className="col-md-6">
            <Slide right>
                <div className="servDetail-txt">
                    <h4>Fun Activities</h4>
                    <p>Celebrate your family's togetherness at the Ravaji Patil Resort, a delightful haven nestled in the heart of Pune. This family-friendly resort in Pune offers a plethora of fun activities to create lasting memories. Immerse yourselves in the rejuvenating swimming pool, where both adults and kids can splash away their stress.</p>
                    <p>With comfortable accommodation options and a warm, welcoming atmosphere, Ravaji Patil Resort in Pune promises a delightful retreat for the entire family, making it the ideal destination for a memorable celebration of togetherness.</p>
                    </div>
                </Slide>
            </div>
          </div> */}
          {/* <div className="row mb-md-0 mb-5">
            <div className="col-md-6 order-md-1 order-2">
            <Slide left>
                <div className="servDetail-txt">
                    <h4>Guest Rooms</h4>
                    <p>Indulge in the epitome of comfort and relaxation at Ravaji Patil Resort in Pune, where the guest rooms redefine family-friendly hospitality. The spacious and tastefully adorned rooms provide a perfect retreat for families, offering a harmonious blend of modern amenities and a tranquil ambiance.</p>
                    <p>Each room is designed with the utmost attention to detail, ensuring a cozy haven for families to unwind after a day of exploring the resort's vibrant offerings. With picturesque views of the surrounding landscape, the guest rooms at Ravaji Patil Resort encapsulate the essence of a rejuvenating stay, making it a premier choice for families seeking a memorable at Pune.</p>
                </div>
                </Slide>
            </div>
            <div className="col-md-6 order-md-2 order-1">
            <Zoom>
                <div className="servDetail-img">
                    <img src={ExcellentRoom} alt="resort room" />
                </div>
                </Zoom>
            </div>
          </div> */}
          <div className="row mb-md-0 mb-5">
            <div className="col-md-6 order-md-1 order-2">
            <Slide right>
                <div className="servDetail-txt">
                    <h4>Delicious Food</h4>
                    <p>Savor an exquisite culinary journey at Ravaji Patil Resort in Pune, where delicious food takes center stage. The resort's dining experience is a gastronomic delight, offering a diverse menu that caters to every palate. From delectable local delicacies to international cuisines, the talented chefs at the resort craft dishes with precision and passion</p>
                    <p>With a commitment to quality and freshness, the resort's culinary offerings are a celebration of flavors, ensuring that every meal becomes a memorable affair. Whether enjoying a hearty breakfast, a leisurely lunch, or a sumptuous dinner, the delicious food at Ravaji Patil Resort elevates the overall stay, making it a true culinary haven in Pune.</p>
                </div>
                </Slide>
            </div>
            <div className="col-md-6 order-md-2 order-1">
            <Zoom>
                <div className="servDetail-img">
                    <img src={Food} alt="resort food" />
                </div>
                </Zoom>
            </div>
          </div>
          {/* <div className="row mb-md-0 mb-5">
            <div className="col-md-6 order-md-1 order-2">
            <Slide left>
                <div className="servDetail-txt">
                    <h4>Tent Stay</h4>
                    <p>Experience the enchantment of nature with a unique tent stay at Ravaji Patil Resort in Pune, where families can immerse themselves in the tranquility of the outdoors. Nestled amidst the resort's lush surroundings, the tent accommodations provide a perfect blend of adventure and comfort for an unforgettable family retreat.</p>
                    <p>These well-appointed tents offer a cozy and secure abode, allowing guests to connect with nature without compromising on luxury. The resort's thoughtful amenities ensure a seamless camping experience, providing families with a chance to bond around a bonfire or stargaze from the comfort of their private outdoor space.</p>
                </div>
                </Slide>
            </div>
            <div className="col-md-6 order-md-2 order-1">
            <Zoom>
                <div className="servDetail-img">
                    <img src={TentStay} alt="tent ravaji patil" />
                </div>
                </Zoom>
            </div>
          </div> */}
        </div>
      </section>
      <Footer/>
    </>
  )
}

export default Services;
