import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import ProjectImages from "../Components/Photo";
import "../CSS/Gallerys.css";
import Footer from "../Components/Footer";
import { Zoom } from 'react-reveal';
import { MetaTags } from "react-meta-tags";

const Gallerys = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
  
    const openLightbox = useCallback((event, { index }) => {
      setCurrentImage(index);
      setViewerIsOpen(true);
    }, []);
  
    const closeLightbox = () => {
      setCurrentImage(0);
      setViewerIsOpen(false);
    };
  
      // popup Modal
      const [modalShow, setModalShow] = React.useState(false);
  
      const [expanded, setExpanded] = React.useState("panel1");
    
      const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };
  return (
    <>
          {/* ========= Seo start========== */}
          <MetaTags>
        <title>Resort In Pune For Couple | Ravaji Patil Resort</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Resort In Pune For Couple | Ravaji Patil Resort" />
        <meta property="og:description" content="" />
        <meta name="keywords" content="resort in pune for couple" />
        <link rel="canonical" href="https://ravajipatilresort.com" />
      </MetaTags>
      {/* ======== Seo end ======= */}
    {/* ========== About Banner ======== */}
    <div className="Gallery-banner">
     <div className="GalleryBanner-Txt">
       <h1 className="d-none">resort in pune for couple</h1>
       <Zoom>
         <h2>Gallery</h2>
       </Zoom>
       <p>
       Discover the essence of family joy at Ravaji Patil Resort's a visual feast of memorable moments
       </p>
     </div>
   </div>
     {/* ======== wedding Gallery ======= */}
     <section className="wedding-gallery">
        <div className="pattern-top" />
        <div className="container">
        <div className="custom-heading">
            <h2>
              <span>Our</span> Gallery  
            </h2>
          </div>
          <Zoom>
          <Gallery photos={ProjectImages} onClick={openLightbox} />
          </Zoom>
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={ProjectImages.map((x) => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </section>
      <Footer/>
    </>
  )
}

export default Gallerys;
